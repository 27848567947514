<template>
  <!-- <div class="content-margin">
    <v-card flat style="overflow: hidden">
      <v-row no-gutters v-if="notificationLoading">
      <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
       <v-sheet class="mt-2">
        <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <div v-else>
      <v-app-bar app :style="`background: ${ $vuetify.theme.dark ? '' : systemDetails.themecolor }`" tile height="62" width="100%">
        <v-layout row wrap align-center class="my-0 text-center">
          <v-flex xs12>
            <template>
              <v-icon class="pb-2 pr-2" size="30" color="#FFFFFF">mdi-bullhorn</v-icon>
              <span style="font-size:25px" class="mt-3 white--text">{{ $t('whatsNew') }}</span>
            </template>
          </v-flex>
        </v-layout>
      </v-app-bar>
      <v-card-text class="px-0 pr-3 pt-0 set-height">
        <div v-if="groupByItems.length" :class="$vuetify.breakpoint.name === 'xs' ? 'time-line-xs' : ''">
          <v-timeline class="timeline pa-0 pt-4" dense>
            <v-lazy>
              <v-slide-x-transition group>
                <v-timeline-item fill-dot right color="grey lighten-1" v-for="(item,index) in groupByItems" :key="index">
                  <v-row>
                    <v-col cols="12" xs="12" class="pl-0">
                      <template>
                        <v-card class="rounded-lg pa-0">
                          <v-card-text class="pa-0 ma-0 px-2">
                            <v-list>
                              <v-list-item class="px-1" v-for="(item, index) in item.groupBy" :key="index">
                                <v-list-item-content class="py-1">
                                  <span v-html="item.iswhatisnew ? item.message : item.content" class="ma-0 pa-0" style="line-height: normal; overflow: hidden"></span>
                                  <span :class="`caption font-weight-bold text-right`" class="ma-0 pa-0" v-html="item.date"></span>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-slide-x-transition>
            </v-lazy>
          </v-timeline>
        </div>
          <div v-else>
            <v-card class="mx-auto transparent" flat>
              <v-layout align-center>
                <v-flex class="text-center">
                  <img width="40.5%" class="pa-2 pt-16 mt-16" src="@/assets/no-whatsnew-available.svg" alt="" />
                  <div>{{ $t('emptyWhatsNewNotificationMsg') }}</div>
                </v-flex>
              </v-layout>
            </v-card>
          </div>
      </v-card-text>
    </div>
    </v-card>
  </div> -->
  <div class="whatsnew-alignment">
    <v-card class="ma-2" flat style="overflow: hidden">
      <v-row no-gutters v-if="notificationLoading">
        <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
          <v-sheet class="mt-2">
            <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
      <div v-else>
        <v-app-bar app :style="`background: ${ $vuetify.theme.dark ? '' : systemDetails.themecolor }`"
          tile height="62" width="100%">
          <v-layout row wrap align-center class="my-0 text-center">
            <v-flex xs12>
              <template>
                <v-icon class="pb-2 pr-2" size="30" color="#FFFFFF">mdi-bullhorn</v-icon>
                <span class="mt-3 text-h5 white--text">{{ $t('whatsNew') }}</span>
              </template>
            </v-flex>
          </v-layout>
        </v-app-bar>
        <v-card-text class="px-0 pr-3 pt-0">
          <div v-if="groupByItems.length" :class="$vuetify.breakpoint.name === 'xs' ? 'time-line-xs' : ''">
            <v-timeline class="pa-0 pt-4  timeline" dense>
              <v-lazy>
                <v-slide-x-transition group>
                  <v-timeline-item  fill-dot right :color="$vuetify.theme.dark ? '' : systemDetails.themecolor"
                    v-for="(item,index) in groupByItems" :key="index">
                    <template v-slot:icon>
                      <v-icon dark size="16" class="mt-1">mdi-message-text</v-icon>
                    </template>
                    <template>
                      <v-row>
                        <v-col cols="12" xs="12" class="pl-0">
                          <template>
                            <v-card outlined tile class="pa-0">
                              <v-card-text class="pa-0 pt-1 ma-0 px-2">
                                <v-row  v-for="(groupItem, index) in item.groupBy" :key="index">
                                  <v-col cols="1" class="pr-0">
                                    <v-icon small :color="$vuetify.theme.dark ? '' : systemDetails.themecolor">mdi-hand-pointing-right</v-icon>
                                  </v-col>
                                  <v-col cols="11" class="pb-0">
                                    <!-- <span class="font-weight-medium text-size" v-html="item.iswhatisnew ? groupItem.message : groupItem.content"></span>  {{ typeof item.iswhatisnew }} -->
                                    <span class="font-weight-medium text-size" v-html=" groupItem.message || groupItem.content"></span>
                                  </v-col>
                                </v-row>
                                <v-row justify="end">
                                  <v-col class="pt-0 pl-0" cols="12">
                                    <div :color="$vuetify.theme.dark ? '' : systemDetails.themecolor"
                                      :class="[ 'text-right','text-size' ,`font-weight-bold text-right`]">
                                      <v-chip small class="mb-2" outlined :color="$vuetify.theme.dark ? '' : systemDetails.themecolor">
                                        <v-icon small left>mdi-calendar-blank</v-icon>
                                        <span class="pr-1  apply-text-size">{{item.date}}</span>
                                      </v-chip>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </template>
                        </v-col>
                      </v-row>
                    </template>
                  </v-timeline-item>
                </v-slide-x-transition>
              </v-lazy>
            </v-timeline>
          </div>
          <div v-else>
            <v-card class="mx-auto transparent" flat>
              <v-layout align-center>
                <v-flex class="text-center">
                  <img width="40.5%" class="pa-2 pt-16 mt-16" src="@/assets/no-whatsnew-available.svg" alt="" />
                  <div>{{ $t('emptyWhatsNewNotificationMsg') }}</div>
                </v-flex>
              </v-layout>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      featuresArray: [],
      pageLoader: true,
      selectedGroupName: '',
      groupByItems: [],
      notificationLoading: false
    }
  },
  computed: {
    ...mapGetters(['systemDetails'])
  },
  mounted () {
    this.getWhatsNewDetails()
  },
  methods: {
    getWhatsNewDetails () {
      this.notificationLoading = true
      this.$api.execute('get', 'whatisnew?isWhatsNew=false').then((response) => {
        if (response.data) this.featuresArray = [...response.data.whatsnew, ...response.data.systemnotification]
        this.groupByItems = response.data.whatsnew
        this.pageLoader = false
        this.featuresArray.forEach((list) => {
          const dateFormat = this.$formatter.formatDate((list.iswhatisnew) ? list.datetodisplayfrom : list.date, '', 'll') // format changes done for PWA date time format
          const day = this.$formatter.formatDate(dateFormat, 'll', 'ddd')
          list.date = day.concat(' ' + `${dateFormat}`)
        })
        const groups = this.featuresArray.reduce((groups, groupBy) => {
          const date = groupBy.date
          if (!groups[date]) {
            groups[date] = []
          }
          groups[date].push(groupBy)
          return groups
        }, {})
        this.groupByItems = Object.keys(groups).map((date) => {
          return {
            date,
            groupBy: groups[date],
            color: groups[date][0].backgroundColor,
            day: groups[date][0].day
          }
        })
      }).finally(() => {
        this.notificationLoading = false
      })
    }
  }
}
</script>
<style>
.timeline .v-timeline-item__divider {
  align-items: flex-start !important;
  /* min-width: 40px !important; */
}
/* .timeline .v-timeline-item__dot {
  height: 15px;
  width: 15px;
} */
.timeline .mdi-message-text::before {
    /* content: "\F0369"; */
    margin-bottom: 2px !important;
}
.timeline .v-timeline-item__dot {
  height: 26px;
  width: 28px;
}
/* .head-bar {
  box-shadow: 5px 0px 5px gainsboro;
  background-color: #EEEEEE;
} */
.timeline.v-timeline:before {
  top: 25px;
  width: 3px;
}
/* .time-line-xs .v-timeline--dense .v-timeline-item__body {
  min-width: calc(95% - 23px);
}
.time-line-xs .v-timeline--dense:not(.v-timeline--reverse):before {
  left: calc(48px - 30px) !important;
}
.time-line-xs .v-timeline-item__divider {
  min-width: 40px !important;
} */
.content-margin p {
  margin-bottom: 4px !important;
}
.content-margin {
  height: calc(100vh - 56px)
}
.set-height {
 height: calc(100vh - 108px) !important;
 overflow-y: auto;
}
.timeline .col-12 {
  padding: 9px;
}
.time-line-xs .v-timeline {
  margin-left: -6vw !important;
}
/* .v-timeline--dense .v-timeline-item__body {
    max-width: calc(100% - 98px);
} */
.text-size {
  font-size:0.800rem !important;
}
.whatsnew-alignment p {
    margin-bottom: 0px;
}
.apply-text-size  {
  font-size: 0.60rem !important;
}
</style>
